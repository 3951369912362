<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" >
        <span v-if="$route.name === 'showInActiveDriver'">{{ $t('transportation.driverRequest') }}</span>
        <span v-else>{{ $t('transportation.driver') }}</span>
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="9">
            <b-card class="mb-4">
              <template v-slot:header>
                <h5 class="text-primary">{{ $t('transportation.personal_data') }}</h5>
              </template>
              <b-row>
                <b-col md="4" class="mb-3">
                    <input-form
                      :placeholder="$t('transportation.full_name')"
                      :label="$t('transportation.full_name')"
                      v-model="data.name"
                      id="name"
                      name="name"
                      validate="required"
                      :disabled="$route.name !== 'editDriver'"
                    ></input-form>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <input-form
                      :placeholder="$t('transportation.phone')"
                      :label="$t('transportation.phone')"
                      v-model="data.phone"
                      id="phone"
                      name="phone"
                      validate="required"
                      :disabled="$route.name !== 'editDriver'"
                    ></input-form>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <input-form
                      :placeholder="$t('transportation.id_number')"
                      :label="$t('transportation.id_number')"
                      v-model="data.idNumber"
                      id="idNumber"
                      name="idNumber"
                      validate="required"
                      :disabled="$route.name !== 'editDriver'"
                    ></input-form>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <input-form
                      :placeholder="$t('transportation.email')"
                      :label="$t('transportation.email')"
                      v-model="data.email"
                      id="email"
                      name="email"
                      :disabled="$route.name !== 'editDriver'"
                    ></input-form>
                  </b-col>
                  <!-- <b-col md="4">
                  <div v-if="$route.name !== 'editDriver'">
                    <input-form
                      :placeholder="$t('transportation.city')"
                      :label="$t('transportation.city')"
                      v-model="data.countryCode"
                      style="font-size: 14px; line-height: 1.8;"
                      disabled
                      name="city"
                    ></input-form>
                  </div>
                  <div v-else>
                    <label class="joining-label">{{$t('transportation.city')}}</label>
                    <main-select
                    class="mb-3 joining-label"
                    :class="{ 'is-disabled': isDisabled }"
                    :name="city"
                    :reduce="city => city.id"
                    :placeholder="city"
                    :options="allCity"
                    v-model="data.city.id"
                    :disabled="$route.name === 'showInActiveDriver' || $route.name === 'showMerchant' "
                    label="name"
                    dir="rtl"
                    />
                  </div>
                </b-col> -->
              </b-row>
            </b-card>
            <b-card  v-if="$route.name == 'editDriver'">
              <template v-slot:header>
                <h5 class="text-primary">{{ $t('transportation.photos') }}</h5>
              </template>
              <div class="mb-4" v-for="(item, index) in data.documents" :key="index">
                <cropper-images
                    :label="item.document.name"
                    @cropper-save="(file) => saveMasterImage(file, index)"
                    :progressLoading="loadingLogo"
                    :showProgress="false"
                    :multi="false"
                    :imageUrl="item.url"
                  />
              </div>
            </b-card>
          </b-col>
          <b-col md="3">
            <div v-if="$route.name !== 'editDriver'">
              <b-card class="mb-4" v-for="(item, index) in data.documents" :key="index">
                <div v-if="$route.name !== 'editDriver'">
                  <span>{{ item.document.name }}</span>
                  <hr />
                  <div class="d-flex gap-3" style="gap: 10px">
                    <img
                      :src="item.url"
                      class="rounded-circle object-fit-cover"
                      style="width: 80px; height: 40px"
                    />
                    <b-button
                      variant="outline-primary"
                      id="show-btn"
                      @click="showModal({ url: item.url, title: item.document.name })"
                      >{{$t('transportation.view_image')}}</b-button
                    >
                  </div>
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5" v-if="$route.name === 'showInActiveDriver'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center"
            style="gap: 10px"
          >
            <div>
              <b-button
                variant="primary"
                class="px-5 py-3"
                id="show-btn"
                v-if="!loadingAccept"
                @click="handleAcceptDriver"
                >قبول</b-button
              >
              <b-button
                v-else
                variant="primary"
                class="text-white px-5 py-3"
                disabled
                type="submit"
              >
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
            </div>
            <div>
              <b-button
                variant="info"
                class="px-5 py-3"
                id="show-btn"
                v-if="!loadingComplate"
                @click="showComplatedModal"
                >استكمال البيانات</b-button
              >
              <b-button
                v-else
                variant="primary"
                class="text-white px-5 py-3"
                disabled
                type="submit"
              >
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
            </div>
            <div>
              <b-button
                variant="outline-danger"
                class="px-5 py-3"
                id="show-btn"
                v-if="!loadingReject"
                @click="showSuspensedModal"
                >رفض</b-button
              >
              <b-button
                v-else
                variant="outline-danger"
                class="text-white px-5 py-3"
                disabled
                type="submit"
              >
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="$route.name == 'editDriver'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center mt-5"
          >
            <b-button
              variant="primary"
              class="px-5 py-3"
              type="submit"
              v-if="!loadingSubmit"
              >تعديل</b-button
            >
            <b-button
              v-else
              variant="primary"
              class="text-white px-5 py-3"
              disabled
              type="submit"
            >
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

    <!-- show photo modal -->
    <b-modal
      ref="modal-photo"
      id="modal-photo"
      centered
      hide-footer
    >
      <template #modal-title>
        {{ selectedPhoto.title }}
      </template>
      <div class="image-container d-block text-center">
        <img :src="selectedPhoto.url" class="img-fluid" />
      </div>
    </b-modal>

    <!-- show complate Data modal -->
    <b-modal
      id="complateModal"
      size="lg"
      title="استكمال البيانات"
      style="direction: rtl"
      centered
      hide-footer
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="d-flex flex-wrap">
            <b-col>
              <textarea-form
                placeholder="السبب"
                v-model="reason"
                label="سبب مختصر"
                name="reason"
                validate="required"
              ></textarea-form>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <div class="d-flex justify-content-center mt-2">
        <b-button
          v-if="saveButton"
          class="btn btn-primary float-right"
          variant="primary"
          disabled
        >
          <spinner-loading text="Loading" />
        </b-button>
        <b-button
          variant="primary"
          v-else
          class="w-50"
          @click="handleComplateData"
          >Save</b-button
        >
      </div>
    </b-modal>

    <!-- show Rejected Driver modal -->
    <b-modal
      id="rejectedModal"
      size="lg"
      title="سبب ايقاف السائق"
      style="direction: rtl"
      centered
      hide-footer
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="d-flex flex-wrap">
            <b-col>
              <textarea-form
                placeholder="السبب"
                v-model="reason"
                label="سبب مختصر"
                name="reason"
                validate="required"
              ></textarea-form>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <div class="d-flex justify-content-center mt-2">
        <b-button
          v-if="saveButton"
          class="btn btn-primary float-right"
          variant="primary"
          disabled
        >
          <spinner-loading text="Loading" />
        </b-button>
        <b-button
          variant="primary"
          v-else
          class="w-50"
          @click="handleRejectDriver"
          >Save</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import api from '../services/transportation'
export default {
  data () {
    return {
      loadingLogo: 0,
      loadingAccept: false,
      loadingComplate: false,
      selectedPhoto: {},
      data: {
        name: '',
        phone: '',
        city: '',
        documents: []
      },
      allCity: []
    }
  },
  components: { },
  methods: {
    // Fetch Main things
    getAllCities () {
      api.getCities().then(res => {
        this.allCity = res.data.data
      })
    },
    getDriver () {
      api.getDriver(this.$route.params.id).then((res) => {
        this.data.name = res.data.data.name
        this.data.phone = res.data.data.phone
        this.data.email = res.data.data.email
        this.data.idNumber = res.data.data.idNumber
        this.data.documents = res.data.data.documents
        this.data.status = res.data.data.status
        this.data.countryCode = res.data.data.countryCode
      })
    },
    // save Image & update
    saveMasterImage (file, index) {
      console.log(file, index)
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadImages(formData, options)
        .then((res) => {
          this.data.documents[index].url = res.data.url
          this.showSuccessUploadFile()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // Handle Edit
    onSubmit () {
      if (this.$route.name === 'editDriver') {
        this.loadingSubmit = true
        api
          .editDriver(this.$route.params.id, this.data)
          .then((res) => {
            this.$router.go(-1)
          })
          .finally(() => {
            this.loadingSubmit = false
          })
      }
      this.loadingSubmit = false
    },
    // Show Models
    showModal (item) {
      this.$refs['modal-photo'].show()
      this.selectedPhoto = { url: item.url, title: item.title }
    },
    showSuspensedModal () {
      this.reason = ''
      this.suspendedId = this.$route.params.id
      this.$bvModal.show('rejectedModal')
    },
    showComplatedModal () {
      this.reason = ''
      this.suspendedId = this.$route.params.id
      this.$bvModal.show('complateModal')
    },
    // Handle Actions
    handleAcceptDriver () {
      this.loadingAccept = true
      api
        .driverStatus(this.$route.params.id, 'accepted')
        .then((res) => {
          core.showSnackbar('success', 'تم  قبول المتجر بنجاح')
          this.$router.push({ name: 'inactiveDrivers' })
        })
        .finally(() => {
          this.loadingAccept = false
        })
    },
    handleRejectDriver () {
      this.saveButton = true
      api
        .driverStatus(this.suspendedId, 'rejected', { mail_body: this.reason })
        .then((res) => {
          core.showSnackbar('success', 'تم  رفض المتجر بنجاح')
          this.saveButton = false
          this.$bvModal.hide('rejectedModal')
          this.$router.push({ name: 'inactiveDrivers' })
        })
    },
    handleComplateData () {
      this.loadingComplate = true
      api
        .driverStatus(this.$route.params.id, 'processing', { mail_body: this.reason })
        .then((res) => {
          core.showSnackbar('success', 'تم  استكمال الطلب بنجاح')
          this.$router.push({ name: 'inactiveDrivers' })
        })
        .finally(() => {
          this.loadingComplate = false
        })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.$route.params.id) {
      console.log('test created')
      this.getDriver()
      this.$root.$on('showSuspensedModal', this.showSuspensedModal)
      this.$root.$on('showComplatedModal', this.showComplatedModal)
    }
  }
}
</script>
